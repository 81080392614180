import * as React from 'react';
import {Content, Name, TestimonialDivider, TestimonialWrapper} from '../TestimonialsStyles';
import {TitleDivider} from "../../IconedListing/IconedListingStyles";

export type Props = {
	testimonial: {name: string, text: string}
};

export const TestimonialLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<TestimonialWrapper>
				<Name>
					{props.testimonial.name}
				</Name>
				<TestimonialDivider />
				<Content>
					{props.testimonial.text}
				</Content>
			</TestimonialWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
