import React from "react";
export const language = {
	"instructions": <>Get a FREE discovery call</>,
	phoneNumberFieldPlaceholder: "Phone Number",
	emailPlaceholder: "Email Address",
	subjectPlaceholder: "Subject",
	messagePlaceholder: "Message",
	 submitButtonText: "Submit",
	responseMessage: "Thank you for submitting! We will contact you soon."
}
