import * as React from 'react';
import {Props as LayoutProps} from "./HomepageLayout"

export type Props = {
	Layout: any
};

export const HomepageContainer = (props: Props) =>
{
	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {

		}
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
