import * as React from 'react';
import {Props as LayoutProps} from "./StatBoxLayout"

export type Props = {
	Layout: any;
	title: string;
	text: string;
	footer: string;
	invert?: boolean;
};

export const StatBoxContainer = (props: Props) =>
{
	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
			title: props.title,
			text: props.text,
			footer: props.footer,
			invert: props.invert ? props.invert : false,
		}
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
