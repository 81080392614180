import * as React from 'react';
import {Props as LayoutProps} from "./ContactFormLayout"
import * as sns from "@aws-sdk/client-sns";
import {PublishCommandInput} from "@aws-sdk/client-sns";
import {useState} from "react";
import {SNSClientConfig} from "@aws-sdk/client-sns/dist-types/SNSClient";
export type Props = {
	Layout: any
};

export const ContactFormContainer = (props: Props) =>
{
	const [submitted, setSubmitted] = useState(false);
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [message, setMessage] = useState("");
	const submitContactForm = async () => {
		const snsConfig: SNSClientConfig = {
			region: 'ca-central-1',
			credentials: {
				accessKeyId: "AKIA4PBAARIHWXDGTXEW",
				secretAccessKey: "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"
			}
		}
		const snsMessage = `Sent by: ${email} (${phoneNumber}). Message: ${message}`;
		const client = new sns.SNSClient(snsConfig);
		const input: PublishCommandInput = { // PublishInput
			TopicArn: "arn:aws:sns:ca-central-1:856913119759:contactform",
			Message: snsMessage, // required
			Subject: "Science Cafe - Contact Form Submitted",
		};
		const command = new sns.PublishCommand(input);
		const response = await client.send(command);
		setSubmitted(true);
	}
	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
			submitContactForm,
			submitted,
			email,
			setEmail,
			phoneNumber,
			setPhoneNumber,
			message,
			setMessage
		}
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
