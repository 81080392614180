import {styled} from "@mui/material/styles"
import {TextareaAutosize} from "@mui/material";

export const ScienceCafeInputFieldWrapper = styled('div')`
	padding-top: .25rem;
  padding-bottom: .25rem;
`


const blue = {
	100: '#DAECFF',
	200: '#b6daff',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	900: '#003A75',
};

const grey = {
	50: '#f6f8fa',
	100: '#eaeef2',
	200: '#d0d7de',
	300: '#afb8c1',
	400: '#8c959f',
	500: '#6e7781',
	600: '#57606a',
	700: '#424a53',
	800: '#32383f',
	900: '#24292f',
};
export const StyledTextArea = styled(TextareaAutosize)`
	 width:  calc(100% - 24px);
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
     color:  #000;
     background:  #fff;
     border: 1px solid  ${grey[700]};
     box-shadow: 0px 2px 24px ${blue[100]};
	};
  
    &:hover {
      //border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
       box-shadow: 0 0 0 3px ${blue[600]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
`;
