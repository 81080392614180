import * as React from 'react';
import {
	ContactFormWrapper,
	FormButtonWrapper,
	FormElementsWrapper,
	FormInstructions,
	SubmittedWrapper
} from './ContactFormStyles';
import {language} from './ContactFormLanguage'
import {Divider, DividerProps} from "@mui/material";
import {ScienceCafeInputFieldLayout, Props as ScienceCafeInputFieldProps} from "../ScienceCafeInputField/ScienceCafeInputFieldLayout";
import {ScienceCafeButtonLayout, Props as ScienceCafeButtonProps} from "../ScienceCafeButton/ScienceCafeButtonLayout";
import {Dispatch, SetStateAction} from "react";

export type Props = {
	submitContactForm: () => void;
	submitted: boolean;
	email: string;
	setEmail: Dispatch<SetStateAction<string>>;
	phoneNumber: string;
	setPhoneNumber: Dispatch<SetStateAction<string>>;
	message: string;
	setMessage: Dispatch<SetStateAction<string>>;
};

export const ContactFormLayout = (props: Props) =>
{
	const render = () =>
	{
		const dividerProps: DividerProps = {
			variant: "fullWidth",
		}
		const phoneFieldProps: ScienceCafeInputFieldProps = {
			type: "textField",
			label: language.phoneNumberFieldPlaceholder,
			value: props.phoneNumber,
			setValue: props.setPhoneNumber
		}
		const emailFieldProps: ScienceCafeInputFieldProps = {
			type: "textField",
			label: language.emailPlaceholder,
			value: props.email,
			setValue: props.setEmail
		}
		const messageProps: ScienceCafeInputFieldProps = {
			type: "textArea",
			label: language.messagePlaceholder,
			value: props.message,
			setValue: props.setMessage
		}
		const submitButtonProps: ScienceCafeButtonProps = {
			variant: "contained",
			text: "Submit",
			onClick: props.submitContactForm,
		}
		const thanksForSubmittingJSX = (
			<ContactFormWrapper>
				<SubmittedWrapper>
					{language.responseMessage}
				</SubmittedWrapper>
			</ContactFormWrapper>
		);
		const unsubmittedJSX = (
			<ContactFormWrapper>
				<FormInstructions>
					{language.instructions}
				</FormInstructions>
				<Divider  {...dividerProps}/>
				<FormElementsWrapper>
					<ScienceCafeInputFieldLayout {...phoneFieldProps} />
					<ScienceCafeInputFieldLayout {...emailFieldProps} />
					<ScienceCafeInputFieldLayout {...messageProps} />
					<FormButtonWrapper>
						<ScienceCafeButtonLayout {...submitButtonProps} />
					</FormButtonWrapper>
				</FormElementsWrapper>
			</ContactFormWrapper>
		);
		const returnValue = props.submitted ? thanksForSubmittingJSX : unsubmittedJSX;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
