import * as React from 'react';
import {Props as LayoutProps} from "./TestimonialsLayout"

export type Props = {
	Layout: any
};

export const testimonials = [
	// {
	// 	name: "Sarah",
	// 	text: "Ruchi is an excellent teacher. I received private chemistry lessons from Ruchi throughout my university education and she was a whiz at simplifying complex problems into bits that were easy to understand.",
	// },
	// {
	// 	name: "Prithika",
	// 	text: "I've had Ruchi Ma'am as my teacher in the past and she's been amazing! Not only does she care deeply about the topics she teaches, but she is also very invested in her students.",
	// },
	// {
	// 	name: "Keval",
	// 	text: "Ruchi has extensive knowledge of chemistry along with significant professional teaching experience. Her strong communication skills and teaching methods enable her students to quickly grasp topics.",
	// },
	{
		name: "Nicole Calle",
		text: "Ruchi is such an amazing educator! She goes through everything in detail so that learning is such a breeze! We were going through biochemical pathways and she turned such a stressful subject into something I look forward to learning! Considering these pathways come in overwhelming detail, she made it super simple and straight to the point. Any questions you have she is able to answer! Looking forward to continuing my academic journey with her!",
	},
	{
		name: "Eric Grosbein",
		text: "Ruchi helped my daughter with Grade 12 Chemistry. She was amazing. Ruchi was always very organized, prepared, and clear with her instructions. My daughter, who is usually very shy, felt very comfortable working with Ruchi..",
	},
	{
		name: "Geetika Singla",
		text: "Ruchi has extensive knowledge of chemistry along with significant professional teaching experience. Her strong communication skills and teaching methods enable her students to quickly grasp topics.",
	},
]

export const TestimonialsContainer = (props: Props) =>
{
	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
			testimonials,
		}
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
