import {styled} from "@mui/material/styles";
import {cssVarColors} from "../../Constants/cssVarColors";
import {mobileThreshold} from "../../Constants/layoutValues";

export const HeaderWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid ${cssVarColors.primary};
  height: 4rem;
  max-height: 4rem;
`;

export const HeaderInternalWrapper = styled('div')`
  width: 1400px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mobileThreshold}px)
  {
  	width: calc(100% - 1rem);
  };
`;

export const LogoWrapper = styled('div')`
  height: calc(100% - 1rem);
  @media (max-width: ${mobileThreshold}px)
  {
  	flex: 1;
	display: flex;
	align-items: center;
  };
`;

export const LogoImage = styled('img')`
  height: 100%;
  @media (max-width: ${mobileThreshold}px)
  {
	height: auto;
  	max-width: 100%;
  };
`;

export const RHSWrapper = styled('div')`
	flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${mobileThreshold}px)
  {
  	flex: 1;
  };
`;
export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
