import * as React from 'react';
import {ContentWrapper, FooterWrapper, LinksWrapper, LogoWrapper, SocialWrapper} from './FooterStyles';
import {language} from './FooterLanguage';
import {SocialBarLayout} from "../SocialBarLayout/SocialBarLayout";
import {CopyrightLayout} from "../SocialBarLayout/Layouts/Copyright/CopyrightLayout";

export type Props = {};

export const FooterLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<FooterWrapper>
				<ContentWrapper>
					<SocialWrapper>
						<SocialBarLayout/>
					</SocialWrapper>
					<CopyrightLayout/>
				</ContentWrapper>
			</FooterWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
