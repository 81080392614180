import * as React from 'react';
import {CopyrightWrapper} from './CopyrightStyles';
import {language} from './CopyrightLanguage'

export type Props = {

};

export const CopyrightLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<CopyrightWrapper>
				{language.copyrightText}
			</CopyrightWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
