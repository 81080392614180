export const cssVarColors = {
	background: '#f0ebf0', // a neutral light grey
	onBackground: '#5a527f', // purple-blue for contrast
	textColor: '#454545', // near-black for strong readability
	onSurface: '#6a3f6d', // use the primary color for pop
	primary: '#6a3f6d', // unchanged
	primaryAlternative: '#442a45', // unchanged
	highlight: '#f17c67', // a warm, vibrant coral color
	secondaryBackground: '#f3e5d3', // a very light variation of secondary
	surface: '#ffffff', // white
	onSurfaceRGB: '31, 78, 121', // a dark, rich blue
	onSurfaceBlue: '#1f4e79', // dark blue for a bit of cool contrast
	onSurfaceYellow: '#e6b800', // a bold yellow for attention
	onSurfaceSecondary: '#b37042', // a darker variant of secondary
	onSurfaceLight: '#b0d7f4', // a very light blue for contrast and lightness
	onPrimary: '#6a3f6d', // same as primary
	onPrimaryAlternative: '#b0d7f4', // a light blue for contrast with primaryAlternative
	secondary: '#f1c26a', // unchanged
	onSecondary: '#442a45', // same as primaryAlternative
	secondaryAlternative: '#ecb244', // unchanged
	onSecondaryAlternative: '#442a45', // same as primaryAlternative
	highlightLightHover: '#ff9b74', // a lighter version of the highlight color
	highlightAlternative: '#d94646', // a bold red for alternative highlights
	warning: '#f44336', // a bold red color for warnings
	error: '#f44336', // a bold red color for errors
	disable: '#a6a6a6', // a mid-grey color for disabled items
	dark: '#121212', // a very dark gray
	placeholder: '#9a9a9a', // a mid-grey color for placeholders
	odd: '#f3e5d3', // a light version of secondary color
	oddAlternate: '#6a3f6d', // same as primary
};
