import * as React from 'react';
import {ScienceCafeLinkWrapper} from './ScienceCafeLinkStyles';
import {language} from './ScienceCafeLinkLanguage'

export type Props = {
	href: string;
	content: string;
};

export const ScienceCafeLinkLayout = (props: Props) =>
{
	const render = () =>
	{
		const linkProps = {
			href: props.href
		}
		const returnValue = (
			<ScienceCafeLinkWrapper {...linkProps}>
				{props.content}
			</ScienceCafeLinkWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
