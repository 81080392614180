import * as React from 'react';
import {FeatureImageWrapper, TaglineWrapper} from './FeatureImageStyles';
import {language} from './FeatureImageLanguage'

export type Props = {

};

export const FeatureImageLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<FeatureImageWrapper>
				<TaglineWrapper>
					{language.tagline}
				</TaglineWrapper>
			</FeatureImageWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
