import {styled} from "@mui/material/styles"
import {headerHeight, mobileFooterPadding, mobileThreshold} from "../../Constants/layoutValues";
import {cssVarColors} from "../../Constants/cssVarColors";

export const FooterWrapper = styled('div')`
	width: 100%;
	background-color: ${cssVarColors.primary};
  color: #fff;
`
export const ContentWrapper = styled('div')`
   position: relative;
	padding-top: 2em;
	color: #fff;
	height: 100%;
	width: calc(100% - ${mobileFooterPadding});
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	@media (max-width: ${mobileThreshold}px) {
	  padding-top: 1rem;
	  flex-direction: column;
	  width: 100%;
	}
`;

export const LogoWrapper = styled('div')`
  padding-left: 1em;
  font-size: 2.5em;
  flex: 3;
  display: flex;
  align-items: flex-start;
  @media (max-width: ${mobileThreshold}px) {
    padding-bottom: 1em;
  }
`;

export const LinksWrapper = styled('div')`
	width: 100%;
	justify-content: space-around;
	display: flex;
	flex-direciton: row;
	flex-grow: 1;
	flex: 1;
	@media (max-width: ${mobileThreshold}px) {
	  padding-top: 1em;
		flex-wrap: wrap;
	  overflow: hidden;
	}
`;

export const SocialWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media (max-width: ${mobileThreshold}px) {
		height: auto;
	  width: 100%;
	}
`;
