import logo from './logo.svg';
import './App.css';
import {HomepageContainer} from "./Pages/Homepage/HomepageContainer";
import {HomepageLayout} from "./Pages/Homepage/HomepageLayout";

function App() {
  return (
	  <HomepageContainer Layout={HomepageLayout} />
  );
}

export default App;
