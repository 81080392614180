import * as React from 'react';
import {ScienceCafeButtonWrapper} from './ScienceCafeButtonStyles';
import {language} from './ScienceCafeButtonLanguage';
import Button, {ButtonPropsColorOverrides, ButtonProps} from '@mui/material/Button';
import {
	createTheme,
	PaletteColorOptions,
	ThemeProvider,
} from '@mui/material/styles';
import {cssVarColors} from "../../Constants/cssVarColors";


export type Props = {
	variant: 'text' | 'outlined' | 'contained';
	text: string;
	onClick?: () => void;
	href?: string;
};

declare module '@mui/material/styles'
{
	interface CustomPalette
	{
		cssVarHighlight: PaletteColorOptions;
		cssVarPrimary: PaletteColorOptions;
		cssVarSecondary: PaletteColorOptions;
	}

	interface Palette extends CustomPalette
	{
	}

	interface PaletteOptions extends CustomPalette
	{
	}
}

declare module '@mui/material/Button'
{
	interface ButtonPropsColorOverrides
	{
		cssVarHighlight: true;
		cssVarPrimary: true;
		cssVarSecondary: true;
	}
}

const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor: any) => augmentColor({color: {main: mainColor}});
const theme = createTheme({
	palette: {
		cssVarHighlight: createColor(cssVarColors.highlight),
		cssVarPrimary: createColor(cssVarColors.primary),
		cssVarSecondary: createColor(cssVarColors.secondary),
	},
	typography: {
		fontFamily: "spacemono"
	}
});
export const ScienceCafeButtonLayout = (props: Props) =>
{
	const render = () =>
	{
		const buttonProps: ButtonProps = {
			variant: props.variant ? props.variant : 'contained',
			color: "cssVarHighlight",
			fullWidth: true,
			onClick: props.onClick,
			href: props.href,
		};
		const returnValue = (
			<ScienceCafeButtonWrapper>
				<ThemeProvider theme={theme}>
					<Button {...buttonProps}>
						{props.text}
					</Button>
				</ThemeProvider>

			</ScienceCafeButtonWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
