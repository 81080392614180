import * as React from 'react';
import {
	BoxFooterWrapper,
	StatBoxWrapper,
	BoxBodyWrapper,
	BoxTitleWrapper, StatBoxInvertedWrapper
} from './StatBoxStyles';
import {language} from './StatBoxLanguage'

export type Props = {
	title: string;
	text: string;
	footer: string;
	invert: boolean;
};

export const StatBoxLayout = (props: Props) =>
{
	const render = () =>
	{
		let Wrapper = StatBoxWrapper
		if (props.invert)
			Wrapper = StatBoxInvertedWrapper;
		const returnValue = (
			<Wrapper>
				<BoxTitleWrapper>
					{props.title}
				</BoxTitleWrapper>
				<BoxBodyWrapper>
					{props.text}
				</BoxBodyWrapper>
				<BoxFooterWrapper>
					{props.footer}
				</BoxFooterWrapper>
			</Wrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
