import * as React from 'react';
import {BodyContentWrapper, BodyImage, BodyImageWrapper, BodyParagraph, BodyWrapper} from './BodyStyles';
import {language} from './BodyLanguage';
import {
	ScienceCafeLinkLayout,
	Props as ScienceCafeLinkProps
} from "../../../../Components/ScienceCafeLink/ScienceCafeLinkLayout";

export type Props = {};

export const BodyLayout = (props: Props) =>
{
	const render = () =>
	{
		const imageProps = {
			src: "https://s3.ca-central-1.amazonaws.com/www.sciencecafe.ca/Assets/Images/RuchiWebsite2.jpg"
		};
		const merrickPrepSchool: ScienceCafeLinkProps = {
			href: "https://www.merrickprep.com/academic-staff",
			content: "Merrick Preparatory School"
		};
		const queensEliteAcademyProps: ScienceCafeLinkProps = {
			href: "https://queenscanada.com/",
			content: "Queen's Elite Academy"
		};

		const redmountProps: ScienceCafeLinkProps = {
			href: "https://www.reidmount.com/",
			content: "Reidmount Saturday School"
		};

		const returnValue = (
			<BodyWrapper>
				<BodyImageWrapper>
					<BodyImage {...imageProps} />
				</BodyImageWrapper>
				<BodyContentWrapper>
					<BodyParagraph>
						Ruchi Khanna is an experienced OCT certified teacher in Ontario with a Bachelor's in Education and a Master's in Chemistry.
					</BodyParagraph>
					<BodyParagraph>
						Ruchi has taught high school students in various countries including Canada, Qatar and India. She is proud of teaching at <ScienceCafeLinkLayout {...merrickPrepSchool} /> in Ottawa with a focus on SCH3U, SCH4U and SNC2D. In addition to in-person classes, she has also taught online courses at <ScienceCafeLinkLayout {...queensEliteAcademyProps} /> using Moodle as the learning platform, and taught enhanced science and math courses at <ScienceCafeLinkLayout {...redmountProps} /> Focusing on basic concepts in chemistry, physics, math and biology, Ruchi is happy to have helped many students who eventually got admissions in top universities in the UK. Canada and the US.
					</BodyParagraph>
					<BodyParagraph>
						Ruchi is passionate and enthusiastic about educating youth, and is dedicated to providing the best experience to all her students. Her teaching techniques are based on the individual needs of the students, and a small baseline test conducted on the first day that helps to design the methodology of the next line of action.
					</BodyParagraph>
					<BodyParagraph>
						Ruchi uses a variety of teaching strategies to meet and support the diverse learning needs of students. She incorporates a variety of creative tools and teaching practices to enhance student involvement through innovative means and the integration of new technologies. She inspires students to love learning science at various levels.
					</BodyParagraph>
				</BodyContentWrapper>
			</BodyWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
