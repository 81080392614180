import * as React from 'react';
import {
	SocialBarWrapper,
	SocialButtonsWrapper,
	MailToAnchor,
	MailAnchorWrapper,
	EnhancedFacebookRounded, EnhancedInstagram, EnhancedLinkedIn
} from './SocialBarStyles';
import { CopyrightLayout } from './Layouts/Copyright/CopyrightLayout';
import { SocialButtonLayout, Props as SocialButtonLayoutProps } from './Layouts/SocialButton/SocialButtonLayout';

export type Props = {};

export type SocialButtonSchema = {
	icon: React.ReactNode;
	link: string;
}

export const SocialBarSchema: Array<SocialButtonSchema> = [
	{
		icon: <EnhancedFacebookRounded />,
		link: 'https://www.facebook.com/sciencecafe.ca',
	},
	{
		icon: <EnhancedInstagram />,
		link: 'http://www.instagram.com/sciencecafe.ca',
	},
	// {
	// 	icon: <EnhancedTwitter />,
	// 	link: "twitter.com"
	// },
	// {
	// 	icon: <EnhancedPinterest />,
	// 	link: 'pintrest.com',
	// },
	{
		icon: <EnhancedLinkedIn />,
		link: 'https://www.linkedin.com/company/sciencecafe-ca/',
	},
];

const iconsSchema = SocialBarSchema;

export const SocialBarLayout = (props: Props) => {
	const render = () => {
		const iconsJSX = iconsSchema.map((iconSchema: SocialButtonSchema, index) => {
			const onClick = () => {
				window.open(iconSchema.link.replace('%url%', iconSchema.link), '_blank');
			};
			const outgoingProps: SocialButtonLayoutProps = {
				icon: iconSchema.icon,
				onClick,
			};
			const returnValue = <SocialButtonLayout key={'socialbutton' + index} {...outgoingProps} />;
			return returnValue;
		});

		const returnValue = (
			<SocialBarWrapper>
				<SocialButtonsWrapper>{iconsJSX}</SocialButtonsWrapper>
				{/*<MailAnchorWrapper>*/}
				{/*	<MailToAnchor href="mailto:support@kidiup.com">Contact us at support@kidiup.com</MailToAnchor>*/}
				{/*</MailAnchorWrapper>*/}
			</SocialBarWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
