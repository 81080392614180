export const contentWidth = '1400px';
export const smallContentWidth = '960px';
export const tabletContentWidth = 'calc(100% - 2rem)';
export const headerHeight = '64px';
export const headerFooterPadding = '8em';
export const mobileHeaderFooterPadding = '2em';
export const mobileFooterPadding = '1em';
export const mobileContentPadding = '2em';
export const tabletContentPadding = '1rem';
export const blogMobileContentPadding = '1em';
export const footerHeight = '400px';
export const mobileFooterHeight = '500px';
export const mobileThreshold = 768;
export const tabletThreshold = 960;
export const smallThreshold = 1280;
export const drawerWidth = 260;
