import * as React from 'react';
import {SocialButtonWrapper} from './SocialButtonStyles';


export type Props = {
	icon: React.ReactNode;
	onClick: () => void;
};

export const SocialButtonLayout = (props: Props) =>
{
	const render = () =>
	{
		const outgoingProps = {
			onClick: props.onClick,
		}
		const returnValue = (
			<SocialButtonWrapper {...outgoingProps}>
				{props.icon}
			</SocialButtonWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
