import * as React from 'react';
import {IconWrapper, ListingRowWrapper, ListingTextWrapper} from "../IconedListingStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/pro-thin-svg-icons";

export type Props = {
	icon: IconName;
	listingItem: string;
};

export const ListingRow = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<ListingRowWrapper>
				<IconWrapper>
					<FontAwesomeIcon icon={['fas', props.icon]} />
				</IconWrapper>
				<ListingTextWrapper>
					{props.listingItem}
				</ListingTextWrapper>
			</ListingRowWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
