import * as React from 'react';
import {HomepageWrapper, InnerContentWrapper, MainContent, OfferingLine, SideBar} from './HomepageStyles';
import {language} from './HomepageLanguage';
import {HeaderContainer} from "../../Components/Header/HeaderContainer";
import {HeaderLayout} from "../../Components/Header/HeaderLayout";
import {FooterContainer} from "../../Components/Footer/FooterContainer";
import {FooterLayout} from "../../Components/Footer/FooterLayout";
import {BodyLayout} from "./Layouts/Body/BodyLayout";
import {StatBoxContainer, Props as StatBoxProps} from "../../Components/StatBox/StatBoxContainer";
import {StatBoxLayout} from "../../Components/StatBox/StatBoxLayout";
import {FeatureImageLayout} from "./Layouts/FeatureImage/FeatureImageLayout";
import {ContactFormContainer} from "../../Components/ContactForm/ContactFormContainer";
import {ContactFormLayout} from "../../Components/ContactForm/ContactFormLayout";
import {IconedListingLayout, Props as ListingProps} from "../../Components/IconedListing/IconedListingLayout";
import {Divider} from "@mui/material";
import {IconName} from "@fortawesome/pro-thin-svg-icons";
import {TestimonialsContainer} from "../../Components/Testimonials/TestimonialsContainer";
import {TestimonialsLayout} from "../../Components/Testimonials/TestimonialsLayout";

export type Props = {};

export const HomepageLayout = (props: Props) =>
{
	const render = () =>
	{
		const scienceBoxProps: StatBoxProps = {
			Layout: StatBoxLayout,
			title: "Science",
			text: "Biology, Chemistry, Physics, grades 6-12",
			footer: "First & Second Year University Chemistry",
			invert: true,
		}
		const mathBoxProps: StatBoxProps = {
			Layout: StatBoxLayout,
			title: "Math",
			text: "Grade 6-10",
			footer: "",
		}
		const homeworkBoxProps: StatBoxProps = {
			Layout: StatBoxLayout,
			title: "Homework Help",
			text: "Homework, assignment & project support",
			footer: "",
			invert: true,
		}
		const examBoxProps: StatBoxProps = {
			Layout: StatBoxLayout,
			title: "Exam Prep",
			text: "GED, SAT, PSAT & more",
			footer: "",
		}
		const howWeCanHelpProps: ListingProps = {
			title: "How We Can Help",
			icon: "fa-clipboard-check" as IconName,
			listing: [
				"Math Tutoring",
				"Biology Tutoring",
				"Chemistry Tutoring",
				"Physics Tutoring",
				"Computer Basics",
				"Intro to Coding",
				"Homework & Assignments",
				"Exam & Test Prep",
				"ESL Support"
			]
		};
		const whyChooseUsProps: ListingProps = {
			title: "Why Choose Us",
			icon: "fa-star" as IconName,
			listing: [
				"Creative Approach",
				"Individualized to Each Student",
				"Online or In-Person Sessions",
				"Flexible Scheduling",
				"Engaging Lessons",
				"Experienced Teachers"
			]
		}
		const testimonialsProps = {
			Layout: TestimonialsLayout,
		}
		const returnValue = (
			<HomepageWrapper>
				<HeaderContainer Layout={HeaderLayout}/>
				<InnerContentWrapper>
					<MainContent>
						<FeatureImageLayout />
						<OfferingLine>
							<StatBoxContainer {...scienceBoxProps}/>
							<StatBoxContainer {...mathBoxProps}/>
							<StatBoxContainer {...homeworkBoxProps}/>
							<StatBoxContainer {...examBoxProps}/>
						</OfferingLine>
						<BodyLayout />
					</MainContent>
					<SideBar>
						<ContactFormContainer Layout={ContactFormLayout} />
						<IconedListingLayout {...howWeCanHelpProps} />
						<Divider variant="middle"/>
						<IconedListingLayout {...whyChooseUsProps} />
					</SideBar>
				</InnerContentWrapper>
				<TestimonialsContainer {...testimonialsProps} />
				<FooterContainer Layout={FooterLayout} />
			</HomepageWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
