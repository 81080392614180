import {styled} from "@mui/material/styles"
import {cssVarColors} from "../../../../Constants/cssVarColors";
import {mobileThreshold} from "../../../../Constants/layoutValues";

export const FeatureImageWrapper = styled('div')`
	height: 300px;
  position: relative;
  background-image: url("https://s3.ca-central-1.amazonaws.com/www.sciencecafe.ca/Assets/Images/Girl+Studying+(1)low.jpg");
  background-size: cover;
  background-position: center center;
  margin-bottom: 2rem;
@media (max-width: ${mobileThreshold}px)
{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 1.5rem;
};
`

export const TaglineWrapper = styled('div')`
  bottom: calc(0px - 1rem);
 left: calc(0px);
  position: absolute;
  background-color: ${cssVarColors.primary};
  color: #fff;
  padding: 1rem;
  font-size: 1.5rem;
  @media (max-width: ${mobileThreshold}px)
  {
	position: relative;
  };
`;
