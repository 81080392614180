import {styled} from "@mui/material/styles"
import {cssVarColors} from "../../Constants/cssVarColors";

export const StatBoxWrapper = styled('div')`
	background-color: ${cssVarColors.primary};
  color: #fff;
  height: 120px;
  flex: 1;
  padding: 1rem;
`

export const StatBoxInvertedWrapper = styled(StatBoxWrapper)`
	background-color: ${cssVarColors.secondary};
  color: #000;
`;

export const BoxTitleWrapper = styled('div')`
	text-align: center;
  padding-bottom: 1rem;
	font-family: montserratSemiBold;
`;

export const BoxBodyWrapper = styled('div')`
	padding-bottom: 1rem;
  text-align: center;
  font-size: .9rem;
`;

export const BoxFooterWrapper = styled('div')`
	text-align: center;
  font-size: .9rem;
`;
