import {styled} from "@mui/material/styles"
import {mobileThreshold} from "../../../../Constants/layoutValues";


export const BodyWrapper = styled('div')`
	padding-top: 1rem;
  line-height: 1.6;
  padding-right: .5rem;
  @media (max-width: ${mobileThreshold}px)
  {
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 1rem;
  };
`;

export const BodyParagraph = styled('div')`
	padding-top: .5rem;
  padding-bottom: .5rem;
  :first-of-type
  {
	padding-top: 0;
  }
  :last-of-type
  {
	padding-bottom: 0;
  }
`;

export const BodyImage = styled('img')`
	width: 100%;
  @media (max-width: ${mobileThreshold}px)
  {
	display: none;
  };
`;

export const BodyImageWrapper = styled('div')`
  width: 100%;
  @media (max-width: ${mobileThreshold}px)
  {
    height: 300px;
    background-image: url("https://s3.ca-central-1.amazonaws.com/www.sciencecafe.ca/Assets/Images/RuchiWebsite2.jpg");
    background-size: cover;
    background-position: center center;
  };
  
`;

export const BodyContentWrapper = styled('div')`
	@media (max-width: ${mobileThreshold}px)
	{
	  padding-right: .5rem;
	  padding-left: .5rem;
	};	
`;
