import {styled} from "@mui/material/styles"
import {contentWidth, mobileThreshold} from "../../Constants/layoutValues";
import {cssVarColors} from "../../Constants/cssVarColors";
import {Divider} from "@mui/material";

export const TestimonialsWrapper = styled('div')`
	display: flex;
   flex-direction: column;
  width: ${contentWidth};
  flex-grow: 1;
  @media (max-width: ${mobileThreshold}px)
  {
  	width: 100%;
  };

`
export const ContentWrapper = styled('div')`
	display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  background-color: ${cssVarColors.background};
  padding-bottom: 2rem;
`;
export const Heading = styled('div')`
	display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-family: montserratSemiBold;
  text-transform: uppercase;
  padding-bottom: 2rem;
`;

export const Body = styled('div')`
	display: flex;
  flex-direction: row;
  @media (max-width: ${mobileThreshold}px)
  {
  	flex-direction: column;
  };
`;

export const TestimonialWrapper = styled('div')`
	display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid ${cssVarColors.primary};
  margin-left: 3rem;
  margin-right: 3rem;
  background-color: #fff;
  //:first-of-type
  //{
	//margin-left: 0;
  //}
  //:last-of-type
  //{
	//margin-right: 0;
  //}
   @media (max-width: ${mobileThreshold}px)
   {
   	margin-bottom: 1rem;
   };
`;

export const Name = styled('div')`
  font-family: montserratLight;
  font-size: 1.2rem;
`;

export const TestimonialDivider = styled(Divider)`
	width: 5rem;
  max-width: 5rem;
  color: ${cssVarColors.primary};
  border-color: ${cssVarColors.primary};
  padding-top: .75rem;
`;


export const Content = styled('div')`
	line-height: 1.6;
  padding-top: 1rem;
`;
