import * as React from 'react';
import {ButtonWrapper, HeaderInternalWrapper, HeaderWrapper, LogoImage, LogoWrapper, RHSWrapper} from './HeaderStyles';
import {language} from './HeaderLanguage'
import {cssVarColors} from "../../Constants/cssVarColors";
import {ScienceCafeButtonLayout, Props as ScienceCafeButtonProps} from "../ScienceCafeButton/ScienceCafeButtonLayout";

export type Props = {

};

export const HeaderLayout = (props: Props) =>
{
	const render = () =>
	{
		const imageProps = {
			src: "https://s3.ca-central-1.amazonaws.com/www.sciencecafe.ca/Assets/Images/Ru_s+Science+Cafe.svg"
		}
		const buttonProps: ScienceCafeButtonProps = {
			variant: 'contained',
			text: language.callButton,
			href: "tel:123-456-7890",
			onClick: () => {},
		}
		const returnValue = (
			<HeaderWrapper>
				<HeaderInternalWrapper>
					<LogoWrapper>
						<LogoImage {...imageProps} />
					</LogoWrapper>
					<RHSWrapper>
						<ButtonWrapper>
							<ScienceCafeButtonLayout {...buttonProps}/>
						</ButtonWrapper>
					</RHSWrapper>
				</HeaderInternalWrapper>
			</HeaderWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
