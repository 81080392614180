import * as React from 'react';
import {ScienceCafeInputFieldWrapper, StyledTextArea} from './ScienceCafeInputFieldStyles';
import {language} from './ScienceCafeInputFieldLanguage'
import {TextField, TextFieldProps, TextareaAutosizeProps} from "@mui/material";
import {Dispatch, SetStateAction} from "react";

export type Props = {
	label: string;
	type: string;
	minRows?: number;
	value: string;
	setValue: Dispatch<SetStateAction<string>>;
};

export const ScienceCafeInputFieldLayout = (props: Props) =>
{
	const updateValue = (e: any) => {
		const value = e.target.value;
		props.setValue(value);
	}
	const render = () =>
	{
		let returnValue = null;
		switch (props.type)
		{
			case "textField":
				const textFieldProps: TextFieldProps = {
					variant: "outlined",
					label: props.label,
					fullWidth: true,
					onKeyUp: e => updateValue(e)
				}
				returnValue = (
					<ScienceCafeInputFieldWrapper>
						<TextField {...textFieldProps} />
					</ScienceCafeInputFieldWrapper>
				)
				break;
			case "textArea":
				const textAreaProps: TextareaAutosizeProps = {
					minRows: props.minRows ? props.minRows : 2,
					placeholder: props.label,
					onKeyUp: e => updateValue(e)
				}
				returnValue = (
					<ScienceCafeInputFieldWrapper>
						<StyledTextArea {...textAreaProps} />
					</ScienceCafeInputFieldWrapper>
				)
				break;
		}
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
