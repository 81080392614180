import * as React from 'react';
import {IconedListingWrapper, IconListingTitle, ListingItemsWrapper, TitleDivider} from './IconedListingStyles';
import {language} from './IconedListingLanguage'
import {ListingRow} from "./Layouts/ListingRow";
import {IconName} from "@fortawesome/pro-thin-svg-icons";

export type Props = {
	title: string;
	icon: IconName;
	listing: Array<string>
};

export const IconedListingLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<IconedListingWrapper>
				<IconListingTitle>
					{props.title}
				</IconListingTitle>
				<TitleDivider />
				<ListingItemsWrapper>
					{generateListing(props.icon, props.listing)}
				</ListingItemsWrapper>
			</IconedListingWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};


export const generateListing = (icon: IconName, listing: Array<string>) => {
	const returnValue = listing.map((listingItem: any, index: number) => {
		const outgoingProps = {
			listingItem,
			icon
		}
		const innerReturnValue = (<ListingRow {...outgoingProps} key={"listingRow" + index}/>);
		return innerReturnValue;
	})
	return returnValue;
};
