import {styled} from "@mui/material/styles";
import {
FacebookRounded,
Instagram,
LinkedIn,
Pinterest,
Twitter
} from '@mui/icons-material'
import {mobileThreshold} from "../../Constants/layoutValues";
import {cssVarColors} from "../../Constants/cssVarColors";

const socialBarPadding = 2;
export const SocialBarWrapper = styled('div')`
	padding-left: ${socialBarPadding}em;
	padding-right: ${socialBarPadding}em;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	width: calc(100% - ${2 * socialBarPadding}em);
	padding-bottom: 1em;
	@media (max-width: ${mobileThreshold}px) {
		padding-bottom: 1em;
		flex-direction: column;
	}
`;

export const SocialButtonsWrapper = styled('div')`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	@media (max-width: ${mobileThreshold}px) {
		width: 100%;
		justify-content: space-between;
	}
`;

const fillColor = 'rgba(255,255,255, .5) !important';
const hoverColor = 'rgba(255,255,255, 1) !important';

export const EnhancedFacebookRounded = styled(FacebookRounded)`
	fill: ${fillColor};
  font-size: 2rem;
	&&:hover {
		fill: ${hoverColor};
	}
`;

export const EnhancedInstagram = styled(Instagram)`
	fill: ${fillColor};
	font-size: 2rem;
	&&:hover {
		fill: ${hoverColor};
	}
`;

export const EnhancedTwitter = styled(Twitter)`
	fill: ${fillColor};
  font-size: 2rem;
	&&:hover {
		fill: ${hoverColor};
	}
`;

export const EnhancedPinterest = styled(Pinterest)`
	fill: ${fillColor};
  font-size: 2rem;
	&&:hover {
		fill: ${hoverColor};
	}
`;

export const EnhancedLinkedIn = styled(LinkedIn)`
	fill: ${fillColor};
  font-size: 2rem;
	&&:hover {
		fill: ${hoverColor};
	}
`;

export const MailAnchorWrapper = styled('div')`
	@media (max-width: ${mobileThreshold}px) {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	} ;
`;

export const MailToAnchor = styled('a')`
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	:hover {
		color: ${cssVarColors.highlight};
	}
`;
