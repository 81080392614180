import {styled} from "@mui/material/styles"
import {contentWidth, mobileThreshold} from "../../Constants/layoutValues";

export const HomepageWrapper = styled('div')`
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @media (max-width: ${mobileThreshold}px)
  {
  	
  };
`

export const InnerContentWrapper = styled('div')`
  width: ${contentWidth};
  max-width: ${contentWidth};
  display: flex;
  flex-direction: row;
  padding-bottom: 1.5rem;
  @media (max-width: ${mobileThreshold}px) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 0;
    flex-direction: column;
  };
`;

export const OfferingLine = styled('div')`
	display: flex;
  flex-direction: row;
  padding-right: .5rem;
 @media (max-width: ${mobileThreshold}px)
 {
   flex-direction: column;
   width: 100%;
 }; 
`;

export const MainContent = styled('div')`
	flex: 7;
`;

export const SideBar = styled('div')`
	flex: 3;
`;
