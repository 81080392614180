import {styled} from "@mui/material/styles"
import {Divider} from "@mui/material";
import {cssVarColors} from "../../Constants/cssVarColors";

export const IconedListingWrapper = styled('div')`
	display: flex;
  flex-direction: column;
  padding-left: 1rem;
  background-color: ${cssVarColors.primary};
  color: #fff;
  padding-top: 1.5rem;
`

export const IconListingTitle = styled('div')`
	font-size: 1.5rem;
  font-weight: 700;
`;

export const TitleDivider = styled(Divider)`
	width: 5rem;
  max-width: 5rem;
  color: #fff;
  border-color: #fff;
  padding-top: .75rem;
`;

export const ListingItemsWrapper = styled('div')`
	padding-bottom: 1rem;	
  font-size: 1.2rem;
`;

export const ListingRowWrapper = styled('div')`
	display: flex;
  flex-direction: row;
  padding-bottom: .5rem;
  padding-top: .5rem;
  :first-of-type
  {
	padding-top: 1rem;
  }
`;

export const IconWrapper = styled('div')`
	padding-right: .5rem;
  color: ${cssVarColors.secondary};
`;

export const ListingTextWrapper = styled('div')`
	flex-grow: 1;
  padding-left: .25rem;
`;
