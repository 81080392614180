import {styled} from "@mui/material/styles";
import {mobileThreshold} from "../../../../Constants/layoutValues";

export const CopyrightWrapper = styled('div')`
	flex: 1;
	text-align: right;
  color: #FFF;
	@media (max-width: ${mobileThreshold}px) {
	  width: 100%;
	  padding-bottom: .5rem;
		text-align: center;
		font-size: 0.7em;
	}
`;
