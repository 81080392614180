import * as React from 'react';
import {Body, ContentWrapper, Heading, TestimonialsWrapper} from './TestimonialsStyles';
import {language} from './TestimonialsLanguage'
import {TestimonialLayout} from "./Layouts/TestimonialLayout";

export type Props = {
	testimonials: Array<{name: string, text: string}>
};

export const TestimonialsLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<TestimonialsWrapper>
				<ContentWrapper>
					<Heading>
						{language.heading}
					</Heading>
					<Body>
						{generateTestimonials(props.testimonials)}
					</Body>
				</ContentWrapper>
			</TestimonialsWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

export const generateTestimonials = (testimonials: Array<{name: string, text: string}>) => {
	const returnValue = testimonials.map((testimonial: {name: string, text: string}) => {
		const testimonialProps = {
			testimonial: testimonial
		}
		const innerReturnValue = <TestimonialLayout {...testimonialProps} />
		return innerReturnValue;
	})
	return returnValue;
}
