import {styled} from "@mui/material/styles"
import {cssVarColors} from "../../Constants/cssVarColors";
import {mobileThreshold} from "../../Constants/layoutValues";

export const ContactFormWrapper = styled('div')`
  height: calc(300px - .5rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${cssVarColors.background};
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  
  margin-bottom: 2rem;
	@media (max-width: ${mobileThreshold}px)
	{
		padding-top: 2rem;
	  padding-bottom: 2rem;
	  margin-bottom: 0;
	};
`

export const SubmittedWrapper = styled('div')`
	display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
`;

export const FormInstructions = styled('div')`
	padding-bottom: .25rem;
  padding-top: .25rem;
`;

export const FormElementsWrapper = styled('div')`
	display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  
`;

export const FormButtonWrapper = styled('div')`
	padding-top: .25rem;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
`;
