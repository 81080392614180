import {styled} from "@mui/material/styles";
import {mobileThreshold} from "../../../../Constants/layoutValues";

export const SocialButtonWrapper = styled('div')`
	padding-left: 0.75em;
	padding-right: 0.75em;
  	cursor: pointer;
	&:hover {
		color: #fff;
	}
  @media (max-width: ${mobileThreshold}px)
  {
	padding-left: .15em;
	padding-right: .15em;
  }
`;
